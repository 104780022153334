import { render, staticRenderFns } from "./Forgot.vue?vue&type=template&id=1f5f797e&scoped=true&"
import script from "./Forgot.vue?vue&type=script&lang=js&"
export * from "./Forgot.vue?vue&type=script&lang=js&"
import style0 from "./Forgot.vue?vue&type=style&index=0&id=1f5f797e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5f797e",
  null
  
)

export default component.exports